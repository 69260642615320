import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { FindMonitMonitorFiltersActions } from '../actions/findmonit-monitor-filters.action';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
  IWorldChartData,
} from '../../../interfaces/filters/filters.interface';
import { FilterSectionName } from 'src/app/shared/enums/filters/filters.enum';

export interface FindMonitMonitorFiltersStateModel {
  filtersSections: IFilterSection[];
  selectedFilters: IFilterSelection;
  filtersSidebarCollapsedState: boolean;
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
  trademarkIrnsList: string[];
}

@State<FindMonitMonitorFiltersStateModel>({
  name: 'filtersMonitor',
  defaults: {
    filtersSections: [],
    selectedFilters: {},
    filtersSidebarCollapsedState: false,
    filtersSectionsCollapsedState: {},
    trademarkIrnsList: [],
  },
})
@Injectable()
export class FindMonitMonitorFiltersState {
  @Selector()
  static getTrademarksIrns(state: FindMonitMonitorFiltersStateModel): string[] {
    return state.trademarkIrnsList;
  }
  /**
   * An object containing properties that correspond to each filter section
   * and each obj property holds an array of strings representing the user selection
   * @param state
   * @returns
   */
  @Selector()
  static getSelectedFilters(state: FindMonitMonitorFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  @Selector()
  static getWorldChartDesignationsData(state: FindMonitMonitorFiltersStateModel): IWorldChartData {
    const designations: IFilterSection | undefined = state.filtersSections.find(
      (section: IFilterSection) => {
        return section.sectionKey === 'designations';
      },
    );

    if (designations) {
      return {
        options: designations.filters[0].options,
        selectedOptions: state.selectedFilters['designations'],
      };
    }
    return {
      options: [],
      selectedOptions: [],
    };
  }

  @Selector()
  static isCanDelegateStatisticsViewVisible(state: FindMonitMonitorFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.CanDelegate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isCanEditStatisticsViewVisible(state: FindMonitMonitorFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.CanEdit && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isDesignationsStatisticsViewVisible(state: FindMonitMonitorFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Designations && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isExpirationDateStatisticsViewVisible(state: FindMonitMonitorFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.ExpirationDate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isHolderNameStatisticsViewVisible(state: FindMonitMonitorFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.HolderName && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isOfficeOfOriginStatisticsViewVisible(state: FindMonitMonitorFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.OfficeOfOrigin && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isRegistrationDateStatisticsViewVisible(
    state: FindMonitMonitorFiltersStateModel,
  ): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.RegistrationDate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isRepresentativeStatisticsViewVisible(state: FindMonitMonitorFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Representative && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isStatusStatisticsViewVisible(state: FindMonitMonitorFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Status && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static getFiltersSidebarCollapsedState(state: FindMonitMonitorFiltersStateModel): boolean {
    return state.filtersSidebarCollapsedState;
  }

  constructor() {}

  /**
   * Whenerver the user changes the collapse states of the sidebar we keep track of this in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.SetFiltersSidebarCollapsed)
  setFiltersSidebarCollapsed(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetFiltersSidebarCollapsed,
  ) {
    context.setState(
      patch<FindMonitMonitorFiltersStateModel>({
        filtersSidebarCollapsedState: action.isCollapsed,
      }),
    );
  }

  /**
   * Whenever the user changes the collapse states of the section, we keep track of these changes in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.SetSectionCollapsed)
  setSectionCollapsed(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetSectionCollapsed,
  ) {
    const { sectionKey, isCollapsed } = action;
    const state = context.getState();

    const updatedCollapsedState = {
      ...state.filtersSectionsCollapsedState,
      [sectionKey]: isCollapsed,
    };

    context.patchState({
      filtersSectionsCollapsedState: updatedCollapsedState,
    });
  }

  /**
   * When we set the filters sections in the states we also check & update the collapsed states for them
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.SetFiltersSections)
  setFiltersSections(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetFiltersSections,
  ) {
    const { sectionData } = action;
    const state = context.getState();

    // Map over sectionData to update the collapsed states based on filtersSectionsCollapsedState
    const updatedSections = sectionData
      ? sectionData.map((section: IFilterSection) => {
          if (
            section &&
            state.filtersSectionsCollapsedState &&
            section.sectionKey in state.filtersSectionsCollapsedState
          ) {
            return {
              ...section,
              collapsed: state.filtersSectionsCollapsedState[section.sectionKey],
            };
          } else {
            return {
              ...section,
              collapsed: true,
            };
          }
        })
      : state.filtersSections;

    // Update the states with the new filtersSections
    const newState: FindMonitMonitorFiltersStateModel = {
      ...state,
      filtersSections: updatedSections,
    };

    context.setState(newState);
  }

  /**
   * Update the selected filters after we remove a tag
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.RemoveTag)
  removeTag(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.RemoveTag,
  ) {
    const state = context.getState();
    const { sectionKey, value } = action;

    if (state.selectedFilters[sectionKey] && Array.isArray(state.selectedFilters[sectionKey])) {
      const index = state.selectedFilters[sectionKey].indexOf(value);
      if (index !== -1) {
        const updatedSelectedFilters = {
          ...state.selectedFilters,
          [sectionKey]: [
            ...state.selectedFilters[sectionKey].slice(0, index),
            ...state.selectedFilters[sectionKey].slice(index + 1),
          ],
        };

        context.patchState({
          selectedFilters: updatedSelectedFilters,
        });
      }
    }
  }

  @Action(FindMonitMonitorFiltersActions.SetQuickFilterSelection)
  setQuickFilterSelection(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetQuickFilterSelection,
  ) {
    const state = context.getState();
    context.patchState({
      selectedFilters: { ...state.selectedFilters, ['quicksearch']: [action.quickSearchTerm] },
    });
  }

  /**
   * Whenerver the user changes the collapse states of the sidebar we keep track of this in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(FindMonitMonitorFiltersActions.SetTrademarksIrns)
  setTrademarksIds(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetTrademarksIrns,
  ) {
    context.setState(
      patch<FindMonitMonitorFiltersStateModel>({
        trademarkIrnsList: action.trademarkIrns,
      }),
    );
  }

  @Action(FindMonitMonitorFiltersActions.SetSelectedFilters)
  setSelectedFilters(
    context: StateContext<FindMonitMonitorFiltersStateModel>,
    action: FindMonitMonitorFiltersActions.SetSelectedFilters,
  ) {
    const { selectedFilters } = action;
    const state = context.getState();

    context.patchState({
      ...state,
      selectedFilters: { ...selectedFilters },
    });
  }
}
