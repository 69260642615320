export const environment = {
  wipoNavBarUrl: 'https://cdn.ipp-navbar.ipportal.acc.web1.wipo.int',
  backofficeUrl: 'https://intra.emadrid-app.madrid.acc.web1.wipo.int',
  gazetteUrl: 'https://www3.wipo.int/madrid/monitor/gaz/query.jsp',
  gazetteImagesUrl: 'https://www.wipo.int/madridwebgazette/images/',
  gazetteRemarksUrl:
    'https://www.wipo.int/madrid/monitor/en/gazette/remarks/{{pubYear}}/{{pubNumber}}/gaz_remarks.htm',
  gazetteRegistrationsUrl:
    'https://www.wipo.int/madrid/monitor/en/gazette/{{pubYear}}/{{pubNumber}}/gaz_registrations.htm',
  gazetteST3Url:
    'https://www.wipo.int/madrid/monitor/en/gazette/{{pubYear}}/{{pubNumber}}/gaz_st3.pdf',
  gazetteST60Url:
    'https://www.wipo.int/madrid/monitor/en/gazette/{{pubYear}}/{{pubNumber}}/gaz_st60.htm',
  gazetteTaxesUrl:
    'https://www.wipo.int/madrid/monitor/en/gazette/{{pubYear}}/{{pubNumber}}/gaz_taxes.htm',
  gazetteFormsUrl:
    'https://www.wipo.int/madrid/monitor/en/gazette/{{pubYear}}/{{pubNumber}}/gaz_forms.htm',
  gazetteNoticeUrl: 'https://www.wipo.int/madrid/en/notices/',
  gazetteSummaryUrl: 'https://www3.wipo.int/madrid/monitor/gaz/query/chapters_xx.json',

  defaultLanguage: 'en',
  oidcIssuer: 'https://www5.wipo.int/am/oauth2',
  oidcClientId: 'emadrid',
  oidcRedirectUri: 'https://cdn.express.emadrid-app.madrid.acc.web1.wipo.int/',
  oidcScope: 'openid profile email',
  oidcResponseType: 'code',
  production: false,
  mockService: false,
  madridMonitorUrl: 'https://www3.wipo.int/madrid/monitor/api/v1/image/WO50000000',
  refundUrl: 'https://www3.wipo.int/finance/en/refund.jsp',
  madridApplicationAssistantUrl: 'https://efiling.madrid.wipo.int/application-assistant/home.xhtml',
  connectionsUrl: 'https://www3dev.wipo.int/wipoaccounts/en/generic/private/connections.xhtml',
  wipoAccountUrl: 'https://logindev.wipo.int/wipoaccounts/en/generic/public/register.xhtml',
  wipoEmail: 'wipo_ip-portal@wipo.int',
  // Renew your registration (Renewal)
  eFormREUrl: 'https://www3-test.wipo.int/madrid/renewal',
  // Expand protection of your registration (Subsequent designation)
  eFormEXUrl: 'https://www3dev.wipo.int/madrid/osd',
  // Renounce protection in contracting parties (Renunciation)
  eFormRNUrl: 'https://www5.wipo.int/renunciation',
  eFormMTUrl: 'https://www5.wipo.int/madrid/holdersinformation/request.xhtml',
  eFormMAUrl: 'https://www5.wipo.int/madrid/managementrepresentative/appointment.xhtml',
  eFormMBUrl: 'https://www5.wipo.int/madrid/managementrepresentative/appointment.xhtml',
  eFormMCUrl: 'https://www5.wipo.int/madrid/managementrepresentative',
  // Limitation of goods and services
  eFormLIUrl: 'https://www5.wipo.int/olf/',
  eFormTRUrl: 'https://www5.wipo.int/madrid/changeownership/transferor.xhtml',
  // Partial change in ownership
  eFormTRPUrl: 'https://www5.wipo.int/madrid/changeownership/',
  // Partial cancellation
  eFormPCUrl: 'https://www5.wipo.int/madrid/cancellation/',
  // Total cancellation
  eFormRAUrl: 'https://www5.wipo.int/madrid/cancellation/',
  eFormMDUUrl: 'https://www3-test.wipo.int/madrid/document-upload',
  paymentUrl: 'https://www.wipo.int/madrid/payment/',
  feeCalculatorUrl: 'https://madrid.wipo.int/feecalcapp/',
  memberProfilesUrl: 'https://www.wipo.int/madrid/memberprofiles/selectmember',
  goodsAndServicesUrl: 'https://webaccess.wipo.int/mgs/',
  estimatedTimingsUrl:
    'https://www.wipo.int/export/sites/www/madrid/en/docs/madrid_pendency_rates.pdf',
  formsUrl: 'https://www.wipo.int/madrid/en/forms/',
  cookieDomain: '.wipo.int',

  countryListUrl: 'https://madrid.wipo.int/feecalculator/',

  //Validation parameters:
  max_file_name_length: 30,
  totalAttachementSize: 36,
  max_file_size: 5000000,
};
