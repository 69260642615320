import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
  IWorldChartData,
} from '@interfaces/filters/filters.interface';
import { FilterSectionName } from 'src/app/shared/enums/filters/filters.enum';
import { ManageTransactionsFiltersActions } from '@store/manage-transactions/actions/manage-transactions-filters.action';
import { ManageTransactionsActions } from '@store/manage-transactions/actions/manage-transactions.actions';
import { ManageTransactionsModel } from '@store/manage-transactions/states/manage-transactions.state';

export interface ManageTransactionsFiltersStateModel {
  filtersSections: IFilterSection[];
  selectedFilters: IFilterSelection;
  filtersSidebarCollapsedState: boolean;
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
  trademarkIRNsList: string[];
}

@State<ManageTransactionsFiltersStateModel>({
  name: 'transactionsFilters',
  defaults: {
    filtersSections: [],
    selectedFilters: {},
    filtersSidebarCollapsedState: false,
    filtersSectionsCollapsedState: {},
    trademarkIRNsList: [],
  },
})
@Injectable()
export class ManageTransactionsFiltersState {
  @Selector()
  static getTrademarksIRNs(state: ManageTransactionsFiltersStateModel): string[] {
    return state.trademarkIRNsList;
  }
  /**
   * An object containing properties that correspond to each filter section
   * and each obj property holds an array of strings representing the user selection
   * @param state
   * @returns
   */
  @Selector()
  static getSelectedFilters(state: ManageTransactionsFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  @Selector()
  static getWorldChartDesignationsData(
    state: ManageTransactionsFiltersStateModel,
  ): IWorldChartData {
    const designations: IFilterSection | undefined = state.filtersSections.find(
      (section: IFilterSection) => {
        return section.sectionKey === FilterSectionName.Designations;
      },
    );

    if (designations) {
      return {
        options: designations.filters[0].options,
        selectedOptions: state.selectedFilters[FilterSectionName.Designations] || [],
      };
    }
    return {
      options: [],
      selectedOptions: [],
    };
  }

  @Selector()
  static getWorldChartOfficeOfOriginData(
    state: ManageTransactionsFiltersStateModel,
  ): IWorldChartData {
    const designations: IFilterSection | undefined = state.filtersSections.find(
      (section: IFilterSection) => {
        return section.sectionKey === FilterSectionName.OfficeOfOrigin;
      },
    );

    if (designations) {
      return {
        options: designations.filters[0].options,
        selectedOptions: state.selectedFilters[FilterSectionName.OfficeOfOrigin] || [],
      };
    }
    return {
      options: [],
      selectedOptions: [],
    };
  }

  @Selector()
  static isCanDelegateStatisticsViewVisible(state: ManageTransactionsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.CanDelegate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isCanEditStatisticsViewVisible(state: ManageTransactionsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.CanEdit && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isDesignationsStatisticsViewVisible(state: ManageTransactionsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Designations && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isExpirationDateStatisticsViewVisible(
    state: ManageTransactionsFiltersStateModel,
  ): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.ExpirationDate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isHolderNameStatisticsViewVisible(state: ManageTransactionsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.HolderName && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isOfficeOfOriginStatisticsViewVisible(
    state: ManageTransactionsFiltersStateModel,
  ): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.OfficeOfOrigin && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isRegistrationDateStatisticsViewVisible(
    state: ManageTransactionsFiltersStateModel,
  ): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.RegistrationDate && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isRepresentativeStatisticsViewVisible(
    state: ManageTransactionsFiltersStateModel,
  ): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Representative && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isStatusStatisticsViewVisible(state: ManageTransactionsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.Status && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static isAccessRightsStatisticsViewVisible(state: ManageTransactionsFiltersStateModel): boolean {
    for (const key in state.filtersSectionsCollapsedState) {
      if (key === FilterSectionName.AccessRights && !state.filtersSectionsCollapsedState[key]) {
        return true;
      }
    }
    return false;
  }

  @Selector()
  static getFiltersSidebarCollapsedState(state: ManageTransactionsFiltersStateModel): boolean {
    return state.filtersSidebarCollapsedState;
  }

  /**
   * Whenever the user changes the collapse states of the sidebar we keep track of this in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(ManageTransactionsFiltersActions.SetFiltersSidebarCollapsed)
  setFiltersSidebarCollapsed(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetFiltersSidebarCollapsed,
  ) {
    context.setState(
      patch<ManageTransactionsFiltersStateModel>({
        filtersSidebarCollapsedState: action.isCollapsed,
      }),
    );
  }

  /**
   * Whenever the user changes the collapse states of the section, we keep track of these changes in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(ManageTransactionsFiltersActions.SetSectionCollapsed)
  setSectionCollapsed(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetSectionCollapsed,
  ) {
    const { sectionKey, isCollapsed } = action;
    const state = context.getState();

    const updatedCollapsedState = {
      ...state.filtersSectionsCollapsedState,
      [sectionKey]: isCollapsed,
    };

    context.patchState({
      filtersSectionsCollapsedState: updatedCollapsedState,
    });
  }

  /**
   * When we set the filters sections in the states we also check & update the collapsed states for them
   * @param context
   * @param action
   */
  @Action(ManageTransactionsFiltersActions.SetFiltersSections)
  setFiltersSections(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetFiltersSections,
  ) {
    const { sectionData } = action;
    const state = context.getState();
    // Map over sectionData to update the collapsed states based on filtersSectionsCollapsedState
    const updatedSections = sectionData
      ? sectionData.map((section: IFilterSection) => ({
          ...section,
          collapsed:
            state.filtersSectionsCollapsedState[section.sectionKey] !== undefined
              ? state.filtersSectionsCollapsedState[section.sectionKey]
              : true,
        }))
      : state.filtersSections;

    // Update the states with the new filtersSections
    const newState: ManageTransactionsFiltersStateModel = {
      ...state,
      filtersSections: updatedSections,
    };

    context.setState(newState);
  }

  /**
   * Update the selected filters after we remove a tag
   * @param context
   * @param action
   */
  @Action(ManageTransactionsFiltersActions.RemoveTag)
  removeTag(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.RemoveTag,
  ) {
    const state = context.getState();
    const { sectionKey, value } = action;

    if (state.selectedFilters[sectionKey] && Array.isArray(state.selectedFilters[sectionKey])) {
      const index = state.selectedFilters[sectionKey].indexOf(value);
      if (index !== -1) {
        const updatedSelectedFilters = {
          ...state.selectedFilters,
          [sectionKey]: [
            ...state.selectedFilters[sectionKey].slice(0, index),
            ...state.selectedFilters[sectionKey].slice(index + 1),
          ],
        };

        context.patchState({
          selectedFilters: updatedSelectedFilters,
        });
      }
    }
  }

  @Action(ManageTransactionsFiltersActions.SetQuickFilterSelection)
  setQuickFilterSelection(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetQuickFilterSelection,
  ) {
    const state = context.getState();
    context.patchState({
      selectedFilters: {
        ...state.selectedFilters,
        ['quicksearch']: [action.quickSearchTerm],
      },
    });
  }

  /**
   * Whenever the user changes the collapse states of the sidebar we keep track of this in the store
   * so we can set it back after a page refresh
   * @param context
   * @param action
   */
  @Action(ManageTransactionsFiltersActions.SetTrademarksIrns)
  setTrademarksIRNs(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetTrademarksIrns,
  ) {
    const IRNs = action.trademarkIrns;
    const state = context.getState();

    context.patchState({
      ...state,
      trademarkIRNsList: IRNs,
    });
  }

  @Action(ManageTransactionsFiltersActions.SetSelectedFilters)
  setSelectedFilters(
    context: StateContext<ManageTransactionsFiltersStateModel>,
    action: ManageTransactionsFiltersActions.SetSelectedFilters,
  ) {
    const { selectedFilters } = action;
    const state = context.getState();

    context.patchState({
      ...state,
      selectedFilters: { ...selectedFilters },
    });
  }

  @Action(ManageTransactionsFiltersActions.ResetSelectedFilters)
  resetSelectedFilters(context: StateContext<ManageTransactionsFiltersStateModel>) {
    const state = context.getState();

    context.patchState({
      ...state,
      selectedFilters: {},
    });
  }

  @Action(ManageTransactionsFiltersActions.ResetCollapsedFilters)
  resetCollapsedFilters(context: StateContext<ManageTransactionsFiltersStateModel>) {
    const state = context.getState();

    context.patchState({
      ...state,
      filtersSectionsCollapsedState: {},
    });
  }
}
