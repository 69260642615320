/* eslint-disable @typescript-eslint/no-namespace */
import { IFilterSection, IFilterSelection } from '@interfaces/filters/filters.interface';

export namespace ManageTmFiltersActions {
  export class SetFiltersSections {
    static readonly type = '[TRADEMARKS-FILTERS] Set Filters Sections';
    constructor(public sectionData: IFilterSection[]) {}
  }

  export class SetFiltersSidebarCollapsed {
    static readonly type = '[TRADEMARKS-FILTERS] Set Filters Sidebar Collapsed State';
    constructor(public isCollapsed: boolean) {}
  }

  export class SetSectionCollapsed {
    static readonly type = '[TRADEMARKS-FILTERS] Set Filter Section Collapsed State';
    constructor(
      public isCollapsed: boolean,
      public sectionKey: string,
    ) {}
  }

  export class RemoveTag {
    static readonly type = '[TRADEMARKS-FILTERS] Remove Tag';
    constructor(
      public sectionKey: string,
      public value: string,
    ) {}
  }

  export class SetQuickFilterSelection {
    static readonly type = '[TRADEMARKS-FILTERS] Set Quick Filter Selection';
    constructor(public quickSearchTerm: string) {}
  }

  export class SetTrademarksIrns {
    static readonly type = '[TRADEMARKS-FILTERS] Set list of trademarks Irns';
    constructor(public trademarkIrns: string[]) {}
  }

  export class SetSelectedFilters {
    static readonly type = '[TRADEMARKS-FILTERS] Set Selected Filters';
    constructor(public selectedFilters: IFilterSelection) {}
  }

  export class ResetSelectedFilters {
    static readonly type = '[TRADEMARKS-FILTERS] Reset Selected Filters';
  }

  export class ResetCollapsedFilters {
    static readonly type = '[TRADEMARKS-FILTERS] Reset Selected Filters';
  }
}
