import { IFilterSection, IFilterSelection } from '@interfaces/filters/filters.interface';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ManageTransactionsFiltersActions {
  export class SetFiltersSections {
    public static readonly type = '[TRANSACTIONS-FILTERS] Set Filters Sections';

    constructor(public sectionData: IFilterSection[]) {}
  }

  export class SetFiltersSidebarCollapsed {
    public static readonly type = '[TRANSACTIONS-FILTERS] Set Filters Sidebar Collapsed State';

    constructor(public isCollapsed: boolean) {}
  }

  export class SetSectionCollapsed {
    public static readonly type = '[TRANSACTIONS-FILTERS] Set Filter Section Collapsed State';

    constructor(
      public isCollapsed: boolean,
      public sectionKey: string,
    ) {}
  }

  export class RemoveTag {
    public static readonly type = '[TRANSACTIONS-FILTERS] Remove Tag';

    constructor(
      public sectionKey: string,
      public value: string,
    ) {}
  }

  export class SetQuickFilterSelection {
    public static readonly type = '[TRANSACTIONS-FILTERS] Set Quick Filter Selection';

    constructor(public quickSearchTerm: string) {}
  }

  export class SetTrademarksIrns {
    public static readonly type = '[TRANSACTIONS-FILTERS] Set list of trademarks Irns';

    constructor(public trademarkIrns: string[]) {}
  }

  export class SetSelectedFilters {
    public static readonly type = '[TRANSACTIONS-FILTERS] Set Selected Filters';

    constructor(public selectedFilters: IFilterSelection) {}
  }

  export class ResetSelectedFilters {
    public static readonly type = '[TRANSACTIONS-FILTERS] Reset Selected Filters';
  }

  export class ResetCollapsedFilters {
    public static readonly type = '[TRANSACTIONS-FILTERS] Reset Selected Filters';
  }
}
