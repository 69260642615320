/* eslint-disable @typescript-eslint/no-namespace */
import { IFilterSection, IFilterSelection } from '../../../interfaces/filters/filters.interface';

export namespace FindMonitMonitorFiltersActions {
  export class SetFiltersSections {
    static readonly type = '[FINDMONIT] Set Filters Sections';
    constructor(public sectionData: IFilterSection[]) {}
  }

  export class SetFiltersSidebarCollapsed {
    static readonly type = '[FINDMONIT] Set Filters Sidebar Collapsed State';
    constructor(public isCollapsed: boolean) {}
  }

  export class SetSectionCollapsed {
    static readonly type = '[FINDMONIT] Set Filter Section Collapsed State';
    constructor(
      public isCollapsed: boolean,
      public sectionKey: string,
    ) {}
  }

  export class RemoveTag {
    static readonly type = '[FINDMONIT] Remove Tag';
    constructor(
      public sectionKey: string,
      public value: string,
    ) {}
  }

  export class SetQuickFilterSelection {
    static readonly type = '[FINDMONIT] Set Quick Filter Selection';
    constructor(public quickSearchTerm: string) {}
  }

  export class SetTrademarksIrns {
    static readonly type = '[FINDMONIT] Set list of trademarks Irns';
    constructor(public trademarkIrns: string[]) {}
  }

  export class SetSelectedFilters {
    static readonly type = '[FINDMONIT] Set Selected Filters';
    constructor(public selectedFilters: IFilterSelection) {}
  }
}
