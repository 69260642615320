import { IAccessRight } from '@interfaces/access-rights/access-right.interface';
import { ITrademarkSearchItems } from '@interfaces/global-search/global-search.interface';

export namespace WatchlistActions {
  export class SetData {
    static readonly type = '[WATCHLIST] Set Data';
    constructor(
      public data: ITrademarkSearchItems[],
      public count: number,
    ) {}
  }

  export class SetSelectedColumns {
    static readonly type = '[WATCHLIST] Set selected columns';
    constructor(public selectedColumns: Array<number>) {}
  }

  export class SetSelectedQuickSearch {
    static readonly type = '[WATCHLIST] Set quick search key';
    constructor(public data: string) {}
  }

  export class SetTmDetailsId {
    static readonly type = '[WATCHLIST] Set Trademark Details ID';
    constructor(public id: string) {}
  }

  export class SetTmUserAccessRights {
    static readonly type = '[WATCHLIST] Set Trademark AccessRights';
    constructor(public userAccessRight: IAccessRight) {}
  }

  export class SetListView {
    static readonly type = '[WATCHLIST] Set List View';
    constructor() {}
  }

  export class SetStatisticsView {
    static readonly type = '[WATCHLIST] Set Statistics View';
    constructor() {}
  }
}
