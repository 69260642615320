import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FindMonitMonitorFiltersState } from './findmonit-monitor-filters.state';
import { FindMonitMonitorActions } from '../actions/findmonit-monitor.action';
import { IAccessRight } from '@interfaces/access-rights/access-right.interface';

export interface FindMonitMonitorModel {
  count: number;
  data: string[];
  listView: boolean;
  searchKey: string;
  selectedColumns: number[];
  statisticsView: boolean;
  tmDetailsId: string;
  tmUserAccessRight: IAccessRight;
}

@State<FindMonitMonitorModel>({
  name: 'findmonitor',
  defaults: {
    count: 0,
    data: [],
    listView: true,
    searchKey: '',
    selectedColumns: [],
    statisticsView: false,
    tmDetailsId: '',
    tmUserAccessRight: { canEdit: false, canDelegate: false },
  },
  children: [FindMonitMonitorFiltersState],
})
@Injectable()
export class FindMonitMonitorState {
  @Selector()
  static getSelectedColumns(state: FindMonitMonitorModel) {
    return state.selectedColumns;
  }

  @Selector()
  static getSelectedQuickSearch(state: FindMonitMonitorModel) {
    return state.searchKey;
  }

  @Selector()
  static getTmDetailsId(state: FindMonitMonitorModel) {
    return state.tmDetailsId;
  }

  @Selector()
  static getTmUserAccessRight(state: FindMonitMonitorModel) {
    return state.tmUserAccessRight;
  }

  @Selector()
  static getListView(state: FindMonitMonitorModel) {
    return state.listView;
  }

  @Selector()
  static getStatisticsView(state: FindMonitMonitorModel) {
    return state.statisticsView;
  }

  constructor() {}

  @Action(FindMonitMonitorActions.SetSelectedColumns)
  setSelectedColumns(context: StateContext<FindMonitMonitorModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      selectedColumns: actionParams['selectedColumns'],
    });
  }

  @Action(FindMonitMonitorActions.SetSelectedQuickSearch)
  setSearchKey(context: StateContext<FindMonitMonitorModel>, searchParam: string) {
    const state = context.getState();
    context.setState({
      ...state,
      searchKey: searchParam,
    });
  }

  @Action(FindMonitMonitorActions.SetTmDetailsId)
  setTmDetailsId(context: StateContext<FindMonitMonitorModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      tmDetailsId: actionParams['id'],
    });
  }

  @Action(FindMonitMonitorActions.SetTmUserAccessRights)
  setTmUserAccesRights(context: StateContext<FindMonitMonitorModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      tmUserAccessRight: actionParams['userAccessRight'],
    });
  }

  @Action(FindMonitMonitorActions.SetListView)
  setListView(context: StateContext<FindMonitMonitorModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      listView: true,
      statisticsView: false,
    });
  }

  @Action(FindMonitMonitorActions.SetStatisticsView)
  setStatisticsView(context: StateContext<FindMonitMonitorModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      listView: false,
      statisticsView: true,
    });
  }
}
