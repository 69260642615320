import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  IFilterSection,
  IFilterSectionCollapsed,
  IFilterSelection,
} from '@interfaces/filters/filters.interface';
import { WatchlistFiltersActions } from '../actions/watchlist-filters.actions';

export interface WatchlistFiltersStateModel {
  filtersSections: IFilterSection[];
  selectedFilters: IFilterSelection;
  filtersSidebarCollapsedState: boolean;
  filtersSectionsCollapsedState: IFilterSectionCollapsed;
}

@State<WatchlistFiltersStateModel>({
  name: 'watchlistFilters',
  defaults: {
    filtersSections: [],
    selectedFilters: {},
    filtersSidebarCollapsedState: true,
    filtersSectionsCollapsedState: {},
  },
})
@Injectable()
export class WatchlistFiltersState {
  @Selector()
  static getSelectedFilters(state: WatchlistFiltersStateModel): IFilterSelection {
    return state.selectedFilters;
  }

  @Selector()
  static getFiltersSidebarCollapsedState(state: WatchlistFiltersStateModel): boolean {
    return state.filtersSidebarCollapsedState;
  }

  @Selector()
  static getFiltersSections(state: WatchlistFiltersStateModel): IFilterSection[] {
    return state.filtersSections;
  }

  @Action(WatchlistFiltersActions.SetFiltersSidebarCollapsed)
  setFiltersSidebarCollapsed(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetFiltersSidebarCollapsed,
  ) {
    context.patchState({
      filtersSidebarCollapsedState: action.isCollapsed,
    });
  }

  @Action(WatchlistFiltersActions.SetSectionCollapsed)
  setSectionCollapsed(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetSectionCollapsed,
  ) {
    const state = context.getState();
    context.patchState({
      filtersSectionsCollapsedState: {
        ...state.filtersSectionsCollapsedState,
        [action.sectionKey]: action.isCollapsed,
      },
    });
  }

  @Action(WatchlistFiltersActions.SetFiltersSections)
  setFiltersSections(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetFiltersSections,
  ) {
    context.patchState({
      filtersSections: action.sectionData,
    });
  }

  @Action(WatchlistFiltersActions.SetSelectedFilters)
  setSelectedFilters(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.SetSelectedFilters,
  ) {
    context.patchState({
      selectedFilters: { ...action.selectedFilters },
    });
  }

  @Action(WatchlistFiltersActions.RemoveTag)
  removeTag(
    context: StateContext<WatchlistFiltersStateModel>,
    action: WatchlistFiltersActions.RemoveTag,
  ) {
    const state = context.getState();
    const { property, value } = action;
    if (state.selectedFilters[property] && Array.isArray(state.selectedFilters[property])) {
      const updatedFilters = {
        ...state.selectedFilters,
        [property]: state.selectedFilters[property].filter((item) => item !== value),
      };
      context.patchState({
        selectedFilters: updatedFilters,
      });
    }
  }
}
