import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ManageTmsFiltersState } from './manage-trademarks-filters.state';
import { ManageTrademarksActions } from '../actions/manage-trademarks.action';
import { IAccessRight } from '@interfaces/access-rights/access-right.interface';

export interface ManageTrademarksModel {
  count: number;
  data: string[];
  listView: boolean;
  searchKey: string;
  selectedColumns: number[];
  statisticsView: boolean;
  tmDetailsIrn: string;
  tmUserAccessRight: IAccessRight;
}

@State<ManageTrademarksModel>({
  name: 'manageTrademarks',
  defaults: {
    count: 0,
    data: [],
    listView: true,
    searchKey: '',
    selectedColumns: [],
    statisticsView: false,
    tmDetailsIrn: '',
    tmUserAccessRight: { canEdit: false, canDelegate: false },
  },
  children: [ManageTmsFiltersState],
})
@Injectable()
export class ManageTrademarksState {
  @Selector()
  static getSelectedColumns(state: ManageTrademarksModel) {
    return state.selectedColumns;
  }

  @Selector()
  static getSelectedQuickSearch(state: ManageTrademarksModel) {
    return state.searchKey;
  }

  @Selector()
  static getTmDetailsId(state: ManageTrademarksModel) {
    return state.tmDetailsIrn;
  }

  @Selector()
  static getTmUserAccessRight(state: ManageTrademarksModel) {
    return state.tmUserAccessRight;
  }

  @Selector()
  static getListView(state: ManageTrademarksModel) {
    return state.listView;
  }

  @Selector()
  static getStatisticsView(state: ManageTrademarksModel) {
    return state.statisticsView;
  }

  @Action(ManageTrademarksActions.SetSelectedColumns)
  setSelectedColumns(context: StateContext<ManageTrademarksModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      selectedColumns: actionParams['selectedColumns'],
    });
  }

  @Action(ManageTrademarksActions.SetSelectedQuickSearch)
  setSearchKey(context: StateContext<ManageTrademarksModel>, searchParam: string) {
    const state = context.getState();
    context.setState({
      ...state,
      searchKey: searchParam,
    });
  }

  @Action(ManageTrademarksActions.SetTmDetailsIrn)
  setTmDetailsId(context: StateContext<ManageTrademarksModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      tmDetailsIrn: actionParams['irn'],
    });
  }

  @Action(ManageTrademarksActions.SetTmUserAccessRights)
  setTmUserAccesRights(context: StateContext<ManageTrademarksModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      tmUserAccessRight: actionParams['userAccessRight'],
    });
  }

  @Action(ManageTrademarksActions.SetListView)
  setListView(context: StateContext<ManageTrademarksModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      listView: true,
      statisticsView: false,
    });
  }

  @Action(ManageTrademarksActions.SetStatisticsView)
  setStatisticsView(context: StateContext<ManageTrademarksModel>, actionParams: any) {
    const state = context.getState();
    context.setState({
      ...state,
      listView: false,
      statisticsView: true,
    });
  }
}
