import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IAccessRight } from '@interfaces/access-rights/access-right.interface';
import { WatchlistActions } from '../actions/watchlist.actions';
import { WatchlistFiltersState } from './watchlist-filters.state';
import { ITrademarkSearchItems } from '@interfaces/global-search/global-search.interface';

export interface WatchlistStateModel {
  count: number;
  data: ITrademarkSearchItems[];
  searchKey: string;
  selectedColumns: number[];
}

@State<WatchlistStateModel>({
  name: 'watchlist',
  defaults: {
    count: 0,
    data: [],
    searchKey: '',
    selectedColumns: [],
  },
  children: [WatchlistFiltersState],
})
@Injectable()
export class WatchlistState {
  @Selector()
  static getData(state: WatchlistStateModel) {
    return state.data;
  }

  @Selector()
  static getCount(state: WatchlistStateModel) {
    return state.count;
  }

  @Selector()
  static getSelectedColumns(state: WatchlistStateModel) {
    return state.selectedColumns;
  }

  @Selector()
  static getSelectedQuickSearch(state: WatchlistStateModel) {
    return state.searchKey;
  }

  @Action(WatchlistActions.SetData)
  setData(ctx: StateContext<WatchlistStateModel>, action: WatchlistActions.SetData) {
    ctx.patchState({
      data: action.data,
      count: action.count,
    });
  }

  @Action(WatchlistActions.SetSelectedColumns)
  setSelectedColumns(
    ctx: StateContext<WatchlistStateModel>,
    action: WatchlistActions.SetSelectedColumns,
  ) {
    ctx.patchState({
      selectedColumns: action.selectedColumns,
    });
  }

  @Action(WatchlistActions.SetSelectedQuickSearch)
  setSearchKey(
    ctx: StateContext<WatchlistStateModel>,
    action: WatchlistActions.SetSelectedQuickSearch,
  ) {
    ctx.patchState({
      searchKey: action.data,
    });
  }
}
